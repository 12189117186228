import React, { useState } from "react";
import { partyInfoTypes } from "../../types";
import { Zoom } from "react-reveal";
import Modal from "../UI/modal";
import Typography from "../Typography";
import { FormInput } from "../form/Input";
import { BsFillTrash2Fill } from "react-icons/bs";
import { setReload } from "../../states/slices/partyInfo.slice";
import { getFormDataHeader } from "../../utils";
import Api from "../../config";
import Button from "../button";
import { PDF, PLUS, UPLOAD } from "../../assets";
import { toast } from "react-hot-toast";
import { Fade } from "react-reveal";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";

interface ModalTypes {
  data: any;
  close: () => void;
  open: boolean;

  isBailiff?: any;
}
function UploadDoc(props: ModalTypes) {
  const [file, setFile] = useState<any>([]);
  const [required, setRequired] = useState<any>("");
  const [title, setTitle] = useState<any>("");

  const navigate = useNavigate();

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    maxSize: 50000000,
    accept: {
      "application/pdf": [".pdf"],
    },
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      setFile(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const productFiles = file.map((item: any, index: number) => (
    <>
      <div className="flex justify-start items-center">
        <img src={PDF} className="h-6 w-6 " />
        <p className="text-center text-green-600 text-sm ml-4" key={index}>
          {item.name}
        </p>
      </div>
      <div className="flex justify-end">
        <BsFillTrash2Fill
          size={"1em"}
          color="red"
          onClick={() => removeAll()}
        />
      </div>
    </>
  ));

  const removeAll = () => {
    setFile([]);
  };

  const handleAddExistingDoc = (e: any) => {
    e.preventDefault();
    let x = 5;
    if (file?.length < 1) {
      setRequired("required");
    } else {
      Api.post(
        props.isBailiff
          ? "bailiff/case-folders/add-document"
          : `lawyer/case-folders/${props.data.id}/add-document`,
        { title, file_path: file[0] },
        getFormDataHeader()
      )
        .then((res) => {
          console.log(res);
          setFile([]);
          props.isBailiff
            ? navigate("/bailiff/service")
            : navigate("/lawyer/my-files");
          toast.success(res.data.message, {
            duration: 3000,
          });
          setRequired("");
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            duration: 3000,
          });
        });
    }
  };
  return (
    <Modal open={props.open} close={props.close} maxWidth="md">
      <>
        <div className="p-8">
          <Fade bottom>
            <>
              <div className="mt-4">
                <div className="flex flex-col   w-full lg:w-[48%]">
                  <label htmlFor="email" className="text-sm">
                    Title <small className="text-red-500">*</small>
                  </label>
                  <FormInput
                    type="text"
                    placeholder="Enter your title"
                    value={title}
                    onChange={(text: React.ChangeEvent<HTMLInputElement>) => {
                      setTitle(text.target.value);
                    }}
                    className="border py-2 border-gray-200  px-4 mt-4 rounded-lg outline-none w-full"
                  />
                </div>
              </div>
              <div className=" py-2 md:py-4" {...getRootProps()}>
                <div className="w-full">
                  {file.length > 0 ? (
                    productFiles
                  ) : (
                    <label className="flex flex-col justify-center items-center min-h-[250px] w-full px-4 transition border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
                      <img src={UPLOAD} alt="" className="h-12" />
                      <p className="flex flex-col items-center space-x-2">
                        <span className="font-medium text-gray-600 mt-4 text-center text-sm">
                          Click to upload or drag and drop <br />
                          Maxiumum file size 10 MB
                        </span>
                        <a className="h-10 px-6 py-2 mt-4 lg:mt-0  ml-4 font-normal items-center text-black bg-BRAND-200 border border-BRAND-200 rounded-xl ">
                          Browse file
                        </a>
                      </p>
                      <span className="text-[#9CA3AF] text-xs mt-4 ">
                        Supported PDF files only
                      </span>
                      <input
                        type="file"
                        name="file_upload"
                        className="hidden"
                        {...getInputProps()}
                      />
                    </label>
                  )}
                </div>
              </div>

              <div className="flex justify-end items-center mt-4 mb-2">
                <Button
                  icons={PLUS}
                  iconClassName="h-[0.9rem] w-3"
                  title="Add Document"
                  bgColor={"bg-brand_indigo"}
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                    handleAddExistingDoc(e)
                  }
                  hoverColor={""}
                  textColor={"text-white"}
                  className={"py-4 rounded-lg"}
                />
              </div>
            </>
          </Fade>
        </div>
      </>
    </Modal>
  );
}

export default UploadDoc;
