import React from "react";
// import { IC_CANCEL } from '../../../assets';
import Button from "../button";
import Modal from "../UI/modal";
import { IC_WARNING } from "../../assets";

function ConfirmationModal({
  isOpen,
  setIsOpen,
  handleClick,
  title,
  isLoading,
}: any) {
  return (
    <Modal open={isOpen} close={() => setIsOpen(false)} maxWidth="xs">
      <div className=" flex justify-center flex-col items-center pb-8 px-8">
        <img src={IC_WARNING} className="h-16 w-16 mx-auto" />
        <div className="mb-8">
          <p className="text-xs text-center">{title}</p>
        </div>

        <div className="flex justify-center  items-center">
          <div className="w-32 ml-4">
            <Button
              onClick={() => setIsOpen(false)}
              title={"Cancel"}
              bgColor={"bg-red-600"}
              textColor={"text-white"}
              className={" mb-2 lg:mb-0 w-full py-4 rounded-2xl"}
            />
          </div>
          <div className="w-32 ml-4">
            <Button
              isLoading={isLoading}
              onClick={handleClick}
              title={"Proceed"}
              bgColor={"bg-brand_indigo"}
              textColor={"text-white"}
              className={" mb-2 lg:mb-0 w-full py-4 rounded-2xl"}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ConfirmationModal;
