/* eslint-disable react/button-has-type */
import { IC_WARNING, WARNING_SIGN } from "../../assets";
import Modal from "../UI/modal";

interface ActionModalProps {
  show: any;
  hide: () => void;
  text: string;
  handleAction: () => void;
  loading: boolean;
}

export const ActionModal = (props: ActionModalProps) => {
  const { show, hide, text, handleAction, loading } = props;
  return (
    <Modal close={hide} maxWidth="xs" open={show}>
      <div className="flex items-center flex-col gap-y-5 px-20 justify-center">
        <img alt="" className="w-[56px] h-[56px]" src={WARNING_SIGN} />
        <span className="text-[20px] font-semibold text-center text-[#474747]">
          {/* Are you sure you want to suspend User? */}
          {text}?
        </span>
        <div className="flex items-center gap-x-3 pb-5">
          <button
            className="text-[17.8px] font-semibold w-[171px] h-[50px] rounded-md bg-[#E0E0E0]"
            onClick={hide}
          >
            Cancel
          </button>
          <button
            className={`text-[17.8px] text-white font-semibold w-[171px] h-[50px] rounded-md ${
              loading ? "bg-opacity-50" : "bg-opacity-100"
            } bg-[#272755]`}
            onClick={handleAction}
          >
            {loading ? "Please wait..." : "Yes"}
          </button>
        </div>
      </div>
    </Modal>
  );
};
