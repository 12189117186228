import React, { useEffect, useState } from "react";
import Typography from "../../../components/Typography";
import {
  MdOutlineDriveFolderUpload,
  MdOutlineSmsFailed,
  MdPendingActions,
} from "react-icons/md";
import { TiTick } from "react-icons/ti";
import Button from "../../../components/button";
import { PLUS, PLUS_DARK } from "../../../assets";
import {
  CASE_COLUMNS,
  CASE_FOLDER_COLUMNS,
  SEARCH_RESULT_COLUMN,
} from "../../../components/table/columns";
import { selectUser } from "../../../states/slices/authReducer";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import CreateCases from "../components/create-cases";
import Api from "../../../config";
import PaginatedTable from "../../../components/table/Paginated-table";
import { getJSONheader } from "../../../utils";
import Modal from "../../../components/UI/modal";
import {
  caseDetailsModal,
  getSelectedCase,
  joinedAs,
  setCaseDetailsModal,
  setJoinAs,
  setSuitNumber,
} from "../../../states/slices/partyInfo.slice";
import { useNavigate } from "react-router-dom";
import CaseDetailsModal from "../components/caseDetailsModal";

function MyFiles() {
  const navigate = useNavigate();
  const caseDetails = useAppSelector(getSelectedCase);
  const [active, setActive] = useState(0);
  const user = useAppSelector(selectUser);
  const [suitmodal, setSuitModal] = useState(false);
  const [suit_no, setSuitNo] = useState("");
  const [open, setOpen] = useState(false);
  const [joincasemodal, setJoinCaseModal] = useState(false);
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const joinedState = useAppSelector(joinedAs);
  const openCaseModal = useAppSelector(caseDetailsModal);

  const fetchData = async (page: number, pageSize: number) => {
    setLoading(true);
    try {
      const response = await Api.get(
        `/lawyer/case-folders?page=${page}`,
        getJSONheader()
      );
      const { data, meta } = response.data;
      setLoading(false);
      setData(data);
      setTotalPages(meta.last_page);
      console.log(data);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData(1, 1);
  }, []);

  return (
    <div className="pt-4 px-2 lg:px-8 pb-36 lg:pb-0">
      <Typography textStyle="text-[20px] font-medium">My Files</Typography>
      <div className="w-full pb-20 lg:pb-8">
        <div className="w-full flex flex-row justify-end lg:justify-between items-start pl-5 pt-4 lg:pt-0">
          <div className="hidden lg:flex justify-between items-center">
            <div
              onClick={() => setActive(0)}
              className={`flex w-full items-center py-2 px-5 ${
                active === 0 ? "text-brand_blue font-medium" : ""
              }`}
            >
              <MdOutlineDriveFolderUpload className="h-5 mr-4" />
              <Typography variant="label">All files (10)</Typography>
            </div>
            <div
              onClick={() => setActive(1)}
              className={`flex w-full items-center py-2 px-5 ${
                active === 1 ? "text-brand_blue font-medium" : ""
              }`}
            >
              <TiTick className="h-5 mr-4" />
              <Typography variant="label">Approved (10)</Typography>
            </div>
            <div
              onClick={() => setActive(2)}
              className={`flex w-full items-center py-2 px-5 ${
                active === 2 ? "text-brand_blue font-medium" : ""
              }`}
            >
              <MdOutlineSmsFailed className="h-5 mr-4" />
              <Typography variant="label">Rejected (10)</Typography>
            </div>
            <div
              onClick={() => setActive(3)}
              className={`flex w-full items-center py-2 px-5 ${
                active === 3 ? "text-brand_blue font-medium" : ""
              }`}
            >
              <MdPendingActions className="h-5 mr-4" />
              <Typography variant="label">On-going (10)</Typography>
            </div>
          </div>

          <Button
            icons={""}
            iconClassName="h-[0.9rem]"
            title="Join a case"
            bgColor={"bg-[#13BAED]"}
            onClick={() => setJoinCaseModal(true)}
            hoverColor={""}
            textColor={"text-white"}
            className={"py-3 w-[160px]"}
          />
          <Button
            icons={PLUS_DARK}
            iconClassName="h-[0.9rem] w-3"
            title="Create Case Folder"
            bgColor={""}
            onClick={() => navigate("/lawyer/create-case")}
            hoverColor={""}
            textColor={"text-black"}
            className={
              "py-3 w-fit border border-gray-200 hover:border-2 hover:border-[#13BAED]"
            }
          />
        </div>

        <div className="mt-8 lg:flex justify-between items-end">
          <div className="w-full lg:mb-0">
            <div className="border-b border-[#F0F0F0] rounded-b-xl">
              <PaginatedTable
                columns={CASE_FOLDER_COLUMNS}
                data={data}
                totalPages={totalPages}
                fetchData={fetchData}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
      <CreateCases {...{ open, setOpen }} />
      <Modal
        maxWidth={"xs"}
        open={joincasemodal}
        close={() => setJoinCaseModal(false)}
      >
        <div className="px-10 flex flex-col gap-y-5">
          <span className="font-semibold text-2xl text-black">
            Select who you are joining as
          </span>

          <div className="flex items-start flex-col gap-y-2">
            <div
              className="flex items-center gap-x-2"
              onClick={() => dispatch(setJoinAs("defendant"))}
            >
              <div className="w-[18px] cursor-pointer h-[18px] border border-[#272755] rounded-full flex items-center justify-center">
                {joinedState === "defendant" && (
                  <div className="w-[10px] h-[10px] rounded-full bg-[#272755]"></div>
                )}
              </div>
              <span className="text-lg font-semibold">Defendant Counsel</span>
            </div>
            <div
              className="flex items-center gap-x-2"
              onClick={() => dispatch(setJoinAs("third_party"))}
            >
              <div className="w-[18px] h-[18px] cursor-pointer border border-[#272755] rounded-full flex items-center justify-center">
                {joinedState === "third_party" && (
                  <div className="w-[10px] h-[10px] rounded-full bg-[#272755]"></div>
                )}
              </div>
              <span className="text-lg font-semibold">Third-Party Counsel</span>
            </div>
          </div>

          <button
            className={`w-full ${
              joinedState === "" && "opacity-50 cursor-not-allowed"
            } py-3 mb-5 rounded font-semibold text-white bg-[#272755] text-[17.8px]`}
            onClick={() => {
              setJoinCaseModal(false);
              setSuitModal(true);
            }}
          >
            Proceed
          </button>
        </div>
      </Modal>
      <Modal maxWidth={"xs"} open={suitmodal} close={() => setSuitModal(false)}>
        <div className="px-10 flex flex-col gap-y-5">
          <span className="font-semibold text-2xl text-black">
            Enter suit number
          </span>

          <input
            type="text"
            value={suit_no}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSuitNo(e.target.value)
            }
            className="w-full px-3 border rounded border-[#CACACA] h-[50px] outline-none"
          />

          <button
            className="w-full py-3 mb-5 rounded font-semibold text-white bg-[#272755] text-[17.8px]"
            onClick={() => {
              navigate(`/lawyer/search-result?suit_number=${suit_no}`);
              dispatch(setSuitNumber(suit_no));
            }}
          >
            Search for case
          </button>
        </div>
      </Modal>
      <CaseDetailsModal
        data={caseDetails}
        hide={() => dispatch(setCaseDetailsModal(false))}
        show={openCaseModal}
      />
    </div>
  );
}

export default MyFiles;
