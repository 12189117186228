import React, { useEffect, useRef, useState } from "react";
import { FaArrowLeft, FaCheck } from "react-icons/fa";
import { useNavigate, useSearchParams } from "react-router-dom";
import Api from "../../../config";
import { ADD_BUTTON, PDF, SUCCESS_IMAGE, UPLOAD_ICON } from "../../../assets";
import { object } from "yup";
import toast from "react-hot-toast";
import { useAppSelector } from "../../../hooks";
import {
  getSelectedCase,
  suit_number,
} from "../../../states/slices/partyInfo.slice";
import { getFormDataHeader } from "../../../utils";
import Modal from "../../../components/UI/modal";
import Preloader from "../../../components/preloader";

const StepSignal = ({
  step,
  currentStep,
  title,
}: {
  step: number;
  currentStep: number;
  title: string;
}) => (
  <div className="flex flex-col items-center gap-y-1">
    <div
      className={`w-[100px] rounded-3xl h-[12px] ${
        currentStep >= step ? "  bg-[#BDBEE0]" : " bg-[#E5E5E5]"
      } `}
    ></div>
    <span
      className={` ${
        currentStep >= step ? "text-black" : "text-[#9A9A9A]"
      } text-xs font-normal `}
    >
      {title}{" "}
    </span>
  </div>
);

type CaseDocType = {
  id: number;
  title: string;
  file: File | null;
};
function FileDefence() {
  const [couselValid, setCouselValid] = useState(false);
  const [couselError, setCouselError] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const [defendants, setDefendants] = useState<any>([]);
  const [docError, setDocError] = useState("");
  const [docValid, setDocValid] = useState(false);
  const fileInputRef = useRef<any>([]);

  const [caseDocument, setCaseDocument] = useState<CaseDocType[]>([
    {
      id: 1,
      title: "",
      file: null,
    },
  ]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const handleFileChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    const file = e.target.files && e.target.files[0];

    const updatedCaseDocs = caseDocument.map((doc, i) =>
      i === id ? { ...doc, file } : doc
    );

    setCaseDocument(updatedCaseDocs);
  };

  const handleCaseInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { name, value } = e.target;
    const updatedCaseDoc = caseDocument.map((doc, i) =>
      i === index ? { ...doc, [name]: value } : doc
    );

    setCaseDocument(updatedCaseDoc);
  };
  const handleRemoveCaseField = (index: number) => {
    const updatedCaseDoc = caseDocument.map((doc, i) =>
      i === index ? { ...doc, file: null } : doc
    );

    setCaseDocument(updatedCaseDoc);
  };

  const handleAddCaseField = () => {
    setCaseDocument([
      ...caseDocument,
      {
        id: caseDocument.length,
        title: "",
        file: null,
      },
    ]);
  };
  const handleNext = () => {
    setCurrentStep((prev) => prev + 1);
  };
  const handlePrev = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const [searchParams] = useSearchParams();
  const idParam = searchParams.get("id") || "";
  const getSuitNumber = useAppSelector(suit_number);
  const [selectedDefendants, setSelectedDefendants] = useState<
    { id: string; name: string }[]
  >([]);
  const [formdata, setFormData] = useState({
    case_document_title: "",

    counsel_detail_first_name: "",
    counsel_detail_whatsapp_number: "",
    counsel_detail_phone_number: "",
    counsel_detail_email: "",
    counsel_detail_address: "",
    counsel_detail_firm_name: "",

    counsel_detail_last_name: "",
  });
  const [successModal, setSuccessModal] = useState(false);
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    validateCounsel();
  };

  const handleCheckboxChange = (defendant: { id: string; name: string }) => {
    if (selectedDefendants.some((d) => d.id === defendant.id)) {
      // If already selected, remove it
      setSelectedDefendants((previous) =>
        previous.filter((d) => d.id !== defendant.id)
      );
    } else {
      // Otherwise, add it to the selected parties
      setSelectedDefendants((previous) => [...previous, defendant]);
    }
  };

  const getCase = useAppSelector(getSelectedCase);
  const getSelectedId = () =>
    selectedDefendants.map((defandant_id) => defandant_id.id);

  const selectedDefandantsId = getSelectedId();
  const [preloaderState, setPreloaderState] = useState<
    "creating" | "almostDone" | "success" | "error" | null
  >(null);
  const handleJoinCase = async () => {
    // setLoading(true);
    setPreloaderState("creating");

    const payload = {
      case_documents: caseDocument.map((doc, index) => ({
        title: doc.title,
        file_path: doc.file,
      })),
      suit_number: getSuitNumber,
      defendant_parties_represented: selectedDefandantsId,
      counsel_detail_first_name: formdata.counsel_detail_first_name,
      counsel_detail_last_name: formdata.counsel_detail_last_name,
      counsel_detail_firm_name: formdata.counsel_detail_firm_name,
      counsel_detail_email: formdata.counsel_detail_email,
      counsel_detail_phone_number: formdata.counsel_detail_phone_number,
      counsel_detail_whatsapp_number: formdata.counsel_detail_whatsapp_number,
      counsel_detail_address: formdata.counsel_detail_address,
    };
    try {
      const response = await Api.post(
        "/lawyer/case-folders/join-as-defendant-counsel",
        payload,

        getFormDataHeader()
      );

      if (response) {
        setTimeout(() => setPreloaderState("almostDone"), 1000);
        setTimeout(() => {
          setPreloaderState("success");
          setSuccessModal(true);
          toast.success(response?.data?.message);
        }, 2000);
      }
    } catch (error: any) {
      setPreloaderState("error");
      setTimeout(() => {
        setPreloaderState(null);
        window.history.back();
      }, 2000);
      toast.error(error?.response?.data?.message || "Error occurred");
    } finally {
      setLoading(false);
    }
  };

  console.log(selectedDefendants);

  const fetchDefendant = async () => {
    try {
      const res = await Api.get(
        `/lawyer/case-folders/${idParam}/defendant-parties`
      );
      if (res) {
        const { data } = res?.data;
        setDefendants(data);
        console.log(data);
      }
    } catch (error) {
      console.error("error", error);
    }
  };
  const validateEmail = (email: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  // Function to validate phone number
  const validatePhone = (phone: string) => {
    const re = /^\d+$/;
    return re.test(phone) && phone.length >= 10;
  };

  const validateCounsel = () => {
    let isValid = true;
    let counsel_error = "";
    if (
      !formdata.counsel_detail_first_name.trim() ||
      !formdata.counsel_detail_last_name.trim() ||
      !formdata.counsel_detail_address.trim() ||
      !formdata.counsel_detail_last_name.trim() ||
      !formdata.counsel_detail_phone_number.trim() ||
      !formdata.counsel_detail_whatsapp_number.trim() ||
      !formdata.counsel_detail_email.trim()
    ) {
      isValid = false;

      counsel_error = "All field are required";
    }

    if (!validateEmail(formdata.counsel_detail_email)) {
      isValid = false;
    }
    if (
      !validatePhone(
        formdata.counsel_detail_phone_number ||
          formdata.counsel_detail_whatsapp_number
      )
    ) {
      isValid = false;
    }

    setCouselValid(isValid);
    setDocValid(isValid);
    setCouselError(counsel_error);
    return isValid;
  };

  useEffect(() => {
    fetchDefendant();
  }, []);

  useEffect(() => {
    return () => {
      fileInputRef.current = [];
    };
  }, []);
  return (
    <main className="flex items-center justify-center flex-col mt-20">
      <span className="text-[28px] font-semibold text-black">
        Defendant Information
      </span>
      <div className="flex items-center gap-x-5 mt-5">
        <StepSignal
          step={1}
          currentStep={currentStep}
          title="Defendants represented"
        />
        <StepSignal
          step={2}
          currentStep={currentStep}
          title="Details of counsel"
        />
        <StepSignal
          step={3}
          currentStep={currentStep}
          title="Upload documents"
        />
      </div>

      {currentStep === 1 && (
        <div className="w-[575px] h-full shadow-lg bg-white p-10 mt-6">
          <span className="text-3xl font-semibold text-black ">
            Defendants represented
          </span>
          <div className="mt-7 flex flex-col gap-y-5">
            {defendants.length > 0 ? (
              defendants.map((data: any) => (
                <div className="flex items-center gap-x-3 ">
                  <div
                    className="w-[25px] h-[25px] border cursor-pointer rounded flex items-center justify-center"
                    onClick={() =>
                      handleCheckboxChange({
                        id: data?.id,
                        name: data.name,
                      })
                    }
                  >
                    {selectedDefendants.some((d) => d.id === data.id) && (
                      <FaCheck size={15} color="black" />
                    )}
                  </div>
                  <span className="text-xl">
                    {data.first_name} {data.last_name}
                  </span>
                </div>
              ))
            ) : (
              <div>No defendants exist in this case folder</div>
            )}
          </div>
          <button
            className={`w-full ${
              selectedDefendants.length > 0
                ? "opacity-100"
                : "opacity-50 cursor-not-allowed"
            } bg-[#272755] text-[17.8px] rounded py-3 mt-9 text-white font-semibold`}
            onClick={handleNext}
            disabled={selectedDefendants.length === 0}
          >
            Next
          </button>
        </div>
      )}

      {currentStep === 2 && (
        <div className="w-[575px] h-full shadow-lg bg-white px-16 py-10 mt-6">
          <span className="text-[20px] font-semibold text-black">
            Details of Counsel
          </span>

          <div className="mt-6">
            <span className="text-[16px] font-semibold">Defendant Counsel</span>

            <form action="" className="flex items-start flex-col gap-y-5 py-3">
              <input
                type="text"
                name="counsel_detail_first_name"
                className={`${
                  couselError && !formdata.counsel_detail_first_name
                    ? "border-red-500"
                    : "border-[#CACACA]"
                }  w-full border  rounded outline-none bg-transparent placeholder:text-sm placeholder:text-black py-4 px-3`}
                placeholder="First name"
                onChange={handleInputChange}
                value={formdata.counsel_detail_first_name}
              />
              <input
                type="text"
                name="counsel_detail_last_name"
                className={` ${
                  couselError && !formdata.counsel_detail_last_name
                    ? "border-red-500"
                    : "border-[#CACACA]"
                } w-full border rounded outline-none bg-transparent placeholder:text-sm placeholder:text-black py-4 px-3`}
                placeholder="Last name"
                onChange={handleInputChange}
                value={formdata.counsel_detail_last_name}
              />
              <input
                type="text"
                name="counsel_detail_firm_name"
                className={` ${
                  couselError && !formdata.counsel_detail_firm_name
                    ? "border-red-500"
                    : "border-[#CACACA]"
                } w-full border  rounded outline-none bg-transparent placeholder:text-sm placeholder:text-black py-4 px-3`}
                placeholder="Name of  law firm"
                onChange={handleInputChange}
                value={formdata.counsel_detail_firm_name}
              />
              <input
                type="email"
                name="counsel_detail_email"
                className={` ${
                  couselError && !formdata.counsel_detail_email
                    ? "border-red-500"
                    : "border-[#CACACA]"
                } w-full border  rounded outline-none bg-transparent placeholder:text-sm placeholder:text-black py-4 px-3`}
                placeholder="Email address"
                onChange={handleInputChange}
                value={formdata.counsel_detail_email}
              />
              <input
                type="text"
                name="counsel_detail_address"
                className={` ${
                  couselError && !formdata.counsel_detail_address
                    ? "border-red-500"
                    : "border-[#CACACA]"
                } w-full border rounded outline-none bg-transparent placeholder:text-sm placeholder:text-black py-4 px-3`}
                placeholder="Address for service"
                onChange={handleInputChange}
                value={formdata.counsel_detail_address}
              />
              <input
                type="text"
                name="counsel_detail_phone_number"
                className={` ${
                  couselError && !formdata.counsel_detail_phone_number
                    ? "border-red-500"
                    : "border-[#CACACA]"
                } w-full border border-[#CACACA] rounded outline-none bg-transparent placeholder:text-sm placeholder:text-black py-4 px-3`}
                placeholder="Phone number"
                onChange={handleInputChange}
                value={formdata.counsel_detail_phone_number}
              />
              <input
                type="text"
                name="counsel_detail_whatsapp_number"
                className={`${
                  couselError && !formdata.counsel_detail_whatsapp_number
                    ? "border-red-500"
                    : "border-[#CACACA]"
                } w-full border border-[#CACACA] rounded outline-none bg-transparent placeholder:text-sm placeholder:text-black py-4 px-3`}
                placeholder="Whatsapp number"
                onChange={handleInputChange}
                value={formdata.counsel_detail_whatsapp_number}
              />
            </form>
          </div>
          <button
            disabled={!couselValid}
            className={` ${
              couselValid ? "opacity-100" : "opacity-50 cursor-not-allowed"
            } w-full bg-[#272755] text-[17.8px] rounded py-3 mt-9 text-white font-semibold`}
            onClick={handleNext}
          >
            Next
          </button>
          <button
            className="flex items-center gap-x-3 text-[17.8px] rounded py-3 mt-9 font-semibold"
            onClick={handlePrev}
          >
            <FaArrowLeft size={20} color="#272755" />
            <span className="text-[#272755] font-medium ">Back</span>
          </button>
        </div>
      )}

      {currentStep === 3 && (
        <div className="w-[575px] h-full shadow-lg bg-white p-10 mt-6">
          <span className="text-[20px] font-semibold text-black">
            Upload case documents
          </span>
          {caseDocument.map((docs, index) => (
            <>
              <div className="mt-2 flex-col flex gap-y-2" key={index}>
                <span className="text-[16px] font-semibold">Case title</span>
                <input
                  type="text"
                  name="title"
                  className="w-full border border-[#CACACA] rounded outline-none bg-transparent placeholder:text-sm placeholder:text-black py-4 px-3"
                  placeholder="Enter case title"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleCaseInputChange(e, index)
                  }
                  value={docs.title}
                />
              </div>

              <div className="mt-6 flex flex-col gap-y-2 items-end justify-end">
                <span className="text-[16px] font-semibold flex w-full items-start">
                  Upload documents
                </span>
                {docs.file ? (
                  <div className="w-full h-full border border-[#C7C7CC] p-10 rounded-lg">
                    <div className="flex items-start gap-x-3">
                      <img src={PDF} className="w-[28px] h-[28px]" alt="" />
                      <div>
                        <div className="flex items-start justify-between">
                          <div className="flex items-start gap-x-3">
                            <div className="flex flex-col gap-y-1">
                              <span className="text-sm font-medium text-[#232A35]">
                                {docs.file.name}
                              </span>
                              <span className="text-[#667085] font-normal text-sm">
                                PDF (max .5 MB)
                              </span>
                            </div>
                          </div>
                          <div className="flex items-center h-[16px] w-[16px] justify-center rounded-full bg-[#272755] ">
                            <FaCheck size={10} color="white" />
                          </div>
                        </div>
                        <div className="flex items-center gap-x-3">
                          <div className="w-[268px] h-[8px] bg-[#272755] rounded-lg"></div>
                          <span>100 %</span>
                        </div>
                        <div className="flex items-center gap-x-2">
                          <button className="text-sm font-semibold text-[#6C757D]">
                            View
                          </button>
                          <button
                            className="text-sm font-semibold text-[#6C757D]"
                            onClick={() => handleRemoveCaseField(index)}
                          >
                            Delete
                          </button>
                          <button
                            className="text-sm font-semibold text-[#6C757D]"
                            onClick={() =>
                              // @ts-ignore
                              // document
                              //   .querySelector(".file_upload")
                              //   [index].click()
                              fileInputRef.current[index].click()
                            }
                          >
                            Upload another
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="w-full h-[126px] cursor-pointer  mt-3 border border-[#EAECF0] flex items-center justify-center flex-col rounded-md"
                    onClick={() =>
                      // @ts-ignore
                      fileInputRef.current[index].click()
                    }
                  >
                    <img
                      src={UPLOAD_ICON}
                      className="w-[40px] h-[40px]"
                      alt=""
                    />

                    <span className="text-[14px] font-semibold">
                      Click to upload{" "}
                      <span className="text-[#667085]">
                        Example receipt.pdf
                      </span>
                    </span>

                    <span className="text-[#667085] text-sm">
                      PDF (max 5mb)
                    </span>
                  </div>
                )}

                <input
                  type="file"
                  accept=".pdf"
                  className="file_upload sr-only"
                  ref={(ref: any) => (fileInputRef.current[index] = ref)}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleFileChange(e, index)
                  }
                />
                <button
                  type="button"
                  onClick={handleAddCaseField}
                  className={`flex justify-end items-end `}
                >
                  <img src={ADD_BUTTON} className="w-[20px] h-[20px] " alt="" />
                </button>
              </div>
            </>
          ))}
          <button
            className={`w-full ${
              loading ? "bg-opacity-50 cursor-wait" : "bg-opacity-100"
            } bg-[#272755] text-[17.8px] rounded py-3 mt-9 text-white font-semibold`}
            onClick={handleJoinCase}
          >
            {loading ? "please wait..." : "  Submit"}
          </button>
        </div>
      )}

      <Modal
        open={successModal}
        close={() => setSuccessModal(false)}
        maxWidth={"xs"}
      >
        <div className="flex items-center justify-center flex-col px-5 py-5">
          <img src={SUCCESS_IMAGE} className="w-[211px] h-[171px]" alt="" />
          <span className="text-[20px] font-semibold">
            Submitted successfully
          </span>

          <button
            className={`w-full bg-[#272755] text-[17.8px] rounded py-3 mt-9 text-white font-semibold`}
            onClick={() => {
              setSuccessModal(false);
              navigate("/lawyer/my-files");
            }}
          >
            Close
          </button>
        </div>
      </Modal>
      <Preloader
        preloaderState={preloaderState}
        action={"Joining a case folder"}
      />
    </main>
  );
}

export default FileDefence;
