/* eslint-disable react/button-has-type */
import React from "react";
import Modal from "../../../components/UI/modal";

interface CaseDetailsProps {
  title: string;
  judicial_division: string | null;
  parties: [
    {
      title: string;
      first_name: string;
      last_name: string;
      email: string;
      phone_number: string;
      whatsapp_number: string;
    }
  ];
  counsel_details: [
    {
      name: string;
      email: string;
      phone_number: string;
      whatsapp_number: string;
    }
  ];
}
interface ModalProps {
  show: boolean;
  hide: () => void;
  data: CaseDetailsProps | null;
}
function CaseDetailsModal(props: ModalProps) {
  return (
    <Modal close={props.hide} maxWidth="xs" open={props.show}>
      <div className="flex items-center justify-center flex-col gap-y-4 px-7 pb-9">
        <span className="text-2xl font-semibold text-black">
          Case folder details
        </span>
        <div className="w-full border-b pb-5 flex flex-col gap-y-3">
          <div className="flex flex-col gap-y-1 items-start">
            <span className="text-sm font-normal text-black">Case title</span>
            <span className="text-[16px] font-medium">{props.data?.title}</span>
          </div>
        </div>

        <div className="flex flex-col items-start gap-y-4 w-full">
          <span className="text-[16px] font-semibold text-black">
            INFORMATION OF PARTIES
          </span>
          {props.data?.parties?.map((item) => (
            <div className="w-full border-b pb-5 flex flex-col gap-y-6">
              <span className="text-[16px] font-semibold text-black">
                {item.title}
              </span>
              <div className="flex flex-col gap-y-3">
                <div className="flex items-center justify-between">
                  <span className="text-sm font-normal text-black">
                    Full name
                  </span>
                  <span className="text-sm font-medium text-black">
                    {item.first_name} {item?.last_name}
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <span className="text-sm font-normal text-black">
                    Email address
                  </span>
                  <span className="text-sm font-medium text-black">
                    {item.email}
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <span className="text-sm font-normal text-black">
                    Phone number
                  </span>
                  <span className="text-sm font-medium text-black">
                    {item.phone_number}
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <span className="text-sm font-normal text-black">
                    Whatsapp number
                  </span>
                  <span className="text-sm font-medium text-black">
                    {item.phone_number}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="w-full border-b pb-5 flex flex-col gap-y-6">
          <span className="text-[16px] font-semibold text-black">
            Information of Representing principal lawyer
          </span>
          {props.data?.counsel_details !== undefined &&
          props.data?.counsel_details.length > 0 ? (
            props.data?.counsel_details?.map((item) => (
              <div className="flex flex-col gap-y-3">
                <div className="flex items-center justify-between">
                  <span className="text-sm font-normal text-black">
                    Full name
                  </span>
                  <span className="text-sm font-medium text-black">
                    {item.name}
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <span className="text-sm font-normal text-black">
                    Email address
                  </span>
                  <span className="text-sm font-medium text-black">
                    {item.email}
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <span className="text-sm font-normal text-black">
                    Phone number
                  </span>
                  <span className="text-sm font-medium text-black">
                    {item.phone_number}
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <span className="text-sm font-normal text-black">
                    Whatsapp number
                  </span>
                  <span className="text-sm font-medium text-black">
                    {item.whatsapp_number ?? "N/A"}
                  </span>
                </div>
              </div>
            ))
          ) : (
            <div>No Counsel Details Available</div>
          )}
        </div>
        <button
          className="w-full py-3 bg-[#272755] rounded-lg text-[17.8px] font-semibold text-white flex justify-center items-center"
          onClick={props.hide}
        >
          Close
        </button>
      </div>
    </Modal>
  );
}

export default CaseDetailsModal;
