import React, { useEffect, useRef, useState } from "react";
import { FaEllipsisV, FaSearch } from "react-icons/fa";
import { PDF_VIEW, PLUS_DARK, SUCCESS_IMAGE } from "../../../assets";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  getSelectedCase,
  getSelectedCaseData,
  joinedAs,
  party_type,
  setJoinAs,
  setParty,
  setSelectedCase,
} from "../../../states/slices/partyInfo.slice";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Modal from "../../../components/UI/modal";
import Button from "../../../components/button";
import moment from "moment";
import { Transition } from "@headlessui/react";
import CaseDetailsModal from "../components/caseDetailsModal";
import { ActionModal } from "../../../components/modals/actionmodal";
import Api from "../../../config";
import toast from "react-hot-toast";

function ViewFolder() {
  const [loading, setLoading] = useState(false);
  const [caseDetailsModal, setCaseDetailsModal] = useState(true);
  // const caseDetails = useAppSelector(getSelectedCaseData);
  const joinedState = useAppSelector(joinedAs);
  const selectedParty = useAppSelector(party_type);
  const [joinAsModal, setJoinAsModal] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const getCaseFolder = useAppSelector(getSelectedCase);
  const [dropdown, setDropdown] = useState<string | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [openFileModal, setOpenFileModal] = useState(false);
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [openCaseModal, setOpenCaseModal] = useState(false);
  const [promptModal, setPromptModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [selectedDocId, setSelectedDocId] = useState<string>("");

  console.log(getCaseFolder);
  const HandleOpenModal = () => (
    <Modal
      close={() => {
        setOpenFileModal(false);
        setFileUrl(null);
      }}
      open={openFileModal}
      maxWidth={"lg"}
    >
      <iframe
        className="w-full"
        height={900}
        src={fileUrl!}
        title="PDF Viewer"
      />
    </Modal>
  );
  const toggleDropDown = (id: string) => {
    setDropdown((prev) => (prev === id ? null : id));
  };

  const closeModal = () => {
    if (getCaseFolder.case_documents.length > 0) {
      setSuccessModal(false);
    } else {
      window.history.back();
    }
  };
  const handleDeleteDoc = async (id: string) => {
    setLoading(true);
    try {
      const response: any = await Api.delete(`/case-documents/${id}`);
      if (response) {
        toast.success(response?.message);
        setPromptModal(false);
        setSuccessModal(true);
        navigate("/lawyer/my-files");
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdown(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <main className="px-7 py-10">
      <div className="flex items-center justify-between">
        <span className="text-[22px] font-bold text-[#101828]">
          {getCaseFolder?.title}
        </span>

        {getCaseFolder?.case_status?.status === "JOIN REQUEST" ||
        getCaseFolder?.case_status?.status === "APPROVED JOIN REQUEST" ? (
          <Button
            icons={PLUS_DARK}
            iconClassName="h-[0.9rem] w-3"
            title="File new document"
            bgColor={""}
            onClick={() => {}}
            hoverColor={""}
            textColor={"text-black"}
            className={"py-3 w-fit border border-gray-200"}
          />
        ) : (
          <>
            {joinedState === "defendant" ? (
              <button
                className=" bg-[#272755] text-sm font-semibold w-[211px] text-white rounded  py-2 px-4"
                onClick={() =>
                  navigate(
                    `/lawyer/file-defence-process/case-folder?id=${getCaseFolder?.id}`
                  )
                }
              >
                File Defence Processes
              </button>
            ) : (
              <button
                className="bg-[#272755] text-sm font-semibold w-[211px] text-white rounded  py-2 px-4"
                onClick={() => setJoinAsModal(true)}
              >
                Join case
              </button>
            )}
          </>
        )}
      </div>
      <div className="w-[264px] h-[40px] rounded-md flex items-center mt-6 justify-between border px-2">
        <input type="text" className="outline-none w-full bg-transparent" />
        <FaSearch size={16} color="#000000" />
      </div>
      <div className="grid grid-cols-3 items-center gap-5 mt-6">
        {getCaseFolder?.case_documents.map((item: any) => (
          <div className="w-full relative h-[303.02px] rounded-xl bg-[#ECEEF5] border-2 border-[#E3E6E9] flex items-center justify-center px-10">
            <img
              src={PDF_VIEW}
              className="w-[294px] h-[254px] rotate-1"
              alt=""
            />
            <div className="w-full h-[100.02px] flex items-center justify-between rounded-b-xl bg-white absolute bottom-0 px-5">
              <div className="flex flex-col items-start gap-y-2">
                <span className="text-[20px] font-medium ">{item?.title}</span>
                <span className="text-[#848484] font-medium text-xs">
                  {/* May 2, 2023 8:45pm */}
                  {moment(item?.created_at).format("MMM D, YYYY h:mma")}
                </span>
              </div>
              <FaEllipsisV
                className="cursor-pointer"
                onClick={() => toggleDropDown(item?.id)}
              />
              <Transition
                as="div"
                ref={dropdownRef}
                className="w-[159px] absolute bg-white shadow-lg h-[120px] rounded-2xl right-5 -bottom-7 pt-3 flex flex-col items-start text-left"
                enter="transition duration-300 ease-out"
                enterFrom="opacity-0 scale-50"
                enterTo="opacity-100 scale-100"
                leave="transition duration-300 ease-out"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-50"
                show={dropdown === item.id}
              >
                <div
                  className="hover:bg-[#DCFFED] pl-4  text-xs w-full  py-2 cursor-pointer"
                  onClick={() => {
                    setFileUrl(item?.file_path);
                    setOpenFileModal(true);
                    setDropdown(null);
                  }}
                >
                  {/* <img
                alt=''
                className='w-[12.5px] h-[12.5px]'
                src={EXPLORER_ICON}
              /> */}
                  <span className="">View file</span>
                </div>
                <div
                  className="hover:bg-[#DCFFED] pl-4  text-xs  w-full py-2 cursor-pointer"
                  onClick={() => {
                    dispatch(setSelectedCase(item));
                    setOpenCaseModal(true);
                    setDropdown(null);
                  }}
                >
                  {/* <img alt='' className='w-[12.5px] h-[12.5px]' src={INFO_ICON} /> */}
                  <span className="">View case details</span>
                </div>
                <div
                  className="hover:bg-[#DCFFED] pl-4  text-xs  w-full py-2 cursor-pointer"
                  onClick={() => {
                    setPromptModal(true);
                    setSelectedDocId(item?.id);
                  }}
                >
                  {/* <img alt='' className='w-[12.5px] h-[12.5px]' src={INFO_ICON} /> */}
                  <span className="">Delete</span>
                </div>
              </Transition>
            </div>
          </div>
        ))}
      </div>
      <Modal maxWidth={"xs"} open={joinAsModal} close={() => setJoinAs(false)}>
        <div className="px-10 flex flex-col gap-y-5">
          <span className="font-semibold text-2xl text-black">
            Select who you are joining as
          </span>

          <div className="flex items-start flex-col gap-y-2">
            <div
              className="flex items-center gap-x-2 "
              onClick={() => dispatch(setParty("DEFENDANT"))}
            >
              <div className="w-[18px] cursor-pointer h-[18px] border border-[#272755] rounded-full flex items-center justify-center">
                {selectedParty === "DEFENDANT" && (
                  <div className="w-[10px] h-[10px] rounded-full bg-[#272755]"></div>
                )}
              </div>
              <span className="text-lg fontsemibold">Defendant Counsel</span>
            </div>
            <div
              className="flex items-center gap-x-2"
              onClick={() => dispatch(setParty("CLAIMANT"))}
            >
              <div className="w-[18px] h-[18px] cursor-pointer border border-[#272755] rounded-full flex items-center justify-center">
                {selectedParty === "CLAIMANT" && (
                  <div className="w-[10px] h-[10px] rounded-full bg-[#272755]"></div>
                )}
              </div>
              <span className="text-lg fontsemibold">Plaintiff Counsel</span>
            </div>
          </div>

          <button
            className={`w-full ${
              joinedState === "" && "opacity-50 cursor-not-allowed"
            } py-3 mb-5 rounded font-semibold text-white bg-[#272755] text-[17.8px]`}
            onClick={() => {
              navigate("/lawyer/join-as-plaintiff");
              setJoinAsModal(false);
            }}
          >
            Proceed
          </button>
        </div>
      </Modal>
      {HandleOpenModal()}
      <Modal
        open={successModal}
        close={() => setSuccessModal(false)}
        maxWidth={"xs"}
      >
        <div className="flex items-center justify-center flex-col px-5 py-5">
          <img src={SUCCESS_IMAGE} className="w-[211px] h-[171px]" alt="" />
          <span className="text-[20px] font-semibold">
            Case document deleted successfully
          </span>

          <button
            className={`w-full bg-[#272755] text-[17.8px] rounded py-3 mt-9 text-white font-semibold`}
            onClick={closeModal}
          >
            Close
          </button>
        </div>
      </Modal>
      {/* <CaseDetailsModal
        data={caseDetails}
        hide={() => setOpenCaseModal(false)}
        show={openCaseModal}
      /> */}

      <Modal
        maxWidth={"xs"}
        open={openCaseModal}
        close={() => setOpenCaseModal(false)}
      >
        <div className="flex items-center justify-center flex-col px-8">
          <span className="text-[24px] font-semibold text-black">
            Document details
          </span>
          <div className="w-full flex flex-col items-start gap-y-3 py-5">
            <div className="flex items-center justify-between w-full">
              <span className="text-black font-normal text-[18px]">
                Document title:
              </span>
              <span className="text-black font-normal text-[18px]">
                Writ of Summons
              </span>
            </div>
            <div className="flex items-center justify-between w-full">
              <span className="text-black font-normal text-[18px]">Owner:</span>
              <span className="text-black font-normal text-[18px]">
                Plaintiff/Applicant
              </span>
            </div>
            <div className="flex items-center justify-between w-full">
              <span className="text-black font-normal text-[18px]">
                Counsel
              </span>
              <span className="text-black font-normal text-[18px]">
                John Eze Esq.
              </span>
            </div>
            <div className="flex items-center justify-between w-full">
              <span className="text-black font-normal text-[18px]">
                Date created:
              </span>
              <span className="text-black font-normal text-[18px]">
                01/10/2024
              </span>
            </div>
            <div className="flex items-center justify-between w-full">
              <span className="text-black font-normal text-[18px]">
                Dated filed:
              </span>
              <span className="text-black font-normal text-[18px]">
                03/10/2024
              </span>
            </div>
          </div>
          <button
            className="w-full py-3 bg-[#272755] rounded-lg mb-7 text-[17.8px] font-semibold text-white flex justify-center items-center"
            onClick={() => setOpenCaseModal(false)}
          >
            Close
          </button>
        </div>
      </Modal>
      <ActionModal
        text="Are you sure you want to delete this document"
        handleAction={() => handleDeleteDoc(selectedDocId)}
        show={promptModal}
        hide={() => setPromptModal(false)}
        loading={loading}
      />
    </main>
  );
}

export default ViewFolder;
