import { PDFDocument } from "pdf-lib";
import { Store } from "../states/store";

export const getJSONheader = () => {
  // const token = localStorage.getItem('token');
  const token = Store.getState().auths.token;
  return {
    headers: {
      // 'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  };
};

export const getFormDataHeader = () => {
  const token = Store.getState().auths.token;
  return {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
};

interface currencyTypes {
  iso: string;
  slug: "NGN" | "USD";
}

export const formatCurrency = (currencyType: currencyTypes) => {
  return new Intl.NumberFormat(currencyType.iso, {
    style: "currency",
    currency: currencyType.slug,
  });
};

export function sumPrices(data: any) {
  let totalPrice = 0;

  for (const item of data) {
    totalPrice += item.price;
  }

  return totalPrice;
}

export const fetchPageOptions = async (pdfUrl: string) => {
  try {
    const existingPdfBytes = await fetch(pdfUrl).then((res) =>
      res.arrayBuffer()
    );
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const pages = pdfDoc.getPages();
    const pageCount = pages.length;

    // Generate an array of page indices
    return Array.from({ length: pageCount }, (_, index) => index);
  } catch (error) {
    console.error("Error fetching page options:", error);
    return [];
  }
};
