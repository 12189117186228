import React from "react";
import "./preloader.css";
import { LOADER_1 } from "../../assets";
const Preloader = ({
  preloaderState,
  action,
}: {
  preloaderState: string | null;
  action: string;
}) => {
  if (!preloaderState) return null;

  let message;
  let icon;
  let showSpinner = false;

  switch (preloaderState) {
    case "creating":
      message = `${action}`;
      showSpinner = true;
      break;
    case "almostDone":
      message = "Almost done";
      showSpinner = true;
      break;
    case "success":
      message = "Success!";
      icon = (
        <svg
          className="checkmark-icon"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 52 52"
          width="52"
          height="52"
        >
          <circle
            className="checkmark-circle"
            cx="26"
            cy="26"
            r="25"
            fill="none"
            stroke="#4CAF50"
            strokeWidth="4"
          ></circle>
          <path
            className="checkmark-check"
            fill="none"
            stroke="#4CAF50"
            strokeWidth="4"
            d="M14 27l8 8 16-16"
          ></path>
        </svg>
      );
      showSpinner = false;
      break;
    case "error":
      message = "Something went wrong!";
      icon = "❌"; // Error icon
      showSpinner = false;
      break;
    default:
      return null;
  }

  return (
    <div className="flex flex-col items-center justify-center gap-y-2 fixed bg-black/80 inset-0 w-full h-full left-0 right-0 top-0 bottom-0">
      {showSpinner && (
        <div className="spinner">
          <img src={LOADER_1} className="w-[250px] h-[250px]" alt="" />
        </div>
      )}
      {icon && <span className="text-6xl">{icon}</span>}
      <p className="text-gray-200 text-4xl">
        {message}

        {(preloaderState === "creating" || preloaderState === "almostDone") && (
          <AnimatedDots />
        )}
      </p>
    </div>
  );
};

export default Preloader;

const AnimatedDots = () => (
  <span className="animated-dots">
    <span>.</span>
    <span>.</span>
    <span>.</span>
  </span>
);
