import React, { useEffect, useRef, useState } from "react";
import { FaArrowLeft, FaCheck, FaMinusCircle } from "react-icons/fa";
import { useNavigate, useSearchParams } from "react-router-dom";
import Api from "../../../config";
import {
  ADD_BUTTON,
  BLUE_ADD,
  GO_BACK,
  PDF,
  SUCCESS_IMAGE,
  UPLOAD_ICON,
} from "../../../assets";
import toast from "react-hot-toast";
import { useAppSelector } from "../../../hooks";
import {
  getSelectedCase,
  party_type,
  suit_number,
} from "../../../states/slices/partyInfo.slice";
import { getFormDataHeader } from "../../../utils";
import Modal from "../../../components/UI/modal";
import Preloader from "../../../components/preloader";

const StepSignal = ({
  step,
  currentStep,
  title,
}: {
  step: number;
  currentStep: number;
  title: string;
}) => (
  <div className="flex flex-col items-center gap-y-1">
    <div
      className={`w-[100px] rounded-3xl h-[12px] ${
        currentStep >= step ? "  bg-[#BDBEE0]" : " bg-[#E5E5E5]"
      } `}
    ></div>
    <span
      className={` ${
        currentStep >= step ? "text-black" : "text-[#9A9A9A]"
      } text-xs font-normal `}
    >
      {title}{" "}
    </span>
  </div>
);

type CaseDocType = {
  title: string;
  file: File | null;
};
function CreateCase() {
  const [currentStep, setCurrentStep] = useState(1);

  const [plaintiffField, setPlaintiffField] = useState([
    {
      partyType: "",
      first_name: "",
      last_name: "",
      other_name: "",
      email: "",
      address: "",
      phone_number: "",
    },
  ]);
  const [defendantField, setDefendantField] = useState([
    {
      partyType: "",
      first_name: "",
      last_name: "",
      other_name: "",
      email: "",
      address: "",
      phone_number: "",
    },
  ]);

  const [caseDocument, setCaseDocument] = useState<CaseDocType[]>([
    {
      title: "",
      file: null,
    },
  ]);
  const [counselValid, setCounselValid] = useState(false);
  const [counselError, setCounselError] = useState("");
  const navigate = useNavigate();
  const fileInputRef = useRef<any>([]);
  const getSuitNumber = useAppSelector(suit_number);
  const [isFormValid, setIsFormValid] = useState<any>(false);
  const selectedPartyType = useAppSelector(party_type);
  const [formdata, setFormData] = useState({
    case_document_title: "",

    counsel_detail_first_name: "",
    counsel_detail_whatsapp_number: "",
    counsel_detail_phone_number: "",
    counsel_detail_email: "",
    counsel_detail_address: "",
    counsel_detail_firm_name: "",

    counsel_detail_last_name: "",
  });
  const [successModal, setSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleFileChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: any
  ) => {
    const file = e.target.files && e.target.files[0];

    const updatedCaseDocs = caseDocument.map((doc, i) =>
      i === id ? { ...doc, file } : doc
    );

    setCaseDocument(updatedCaseDocs);
  };

  const handleCaseInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { name, value } = e.target;
    const updatedCaseDoc = caseDocument.map((doc, i) =>
      i === index ? { ...doc, [name]: value } : doc
    );

    setCaseDocument(updatedCaseDoc);
  };
  const handleDefendantInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    index: number
  ) => {
    const { name, value } = e.target;
    const updatedDefendants = defendantField.map((defendant, i) =>
      i === index ? { ...defendant, [name]: value } : defendant
    );

    setDefendantField(updatedDefendants);
    validateForm();
  };
  const handleClaimantInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    index: number
  ) => {
    const { name, value } = e.target;

    const updatedPlainTiffs = plaintiffField.map((plaintiff, i) =>
      i === index ? { ...plaintiff, [name]: value } : plaintiff
    );

    setPlaintiffField(updatedPlainTiffs);
    validateForm();
  };

  const handleAddCaseField = () => {
    setCaseDocument([
      ...caseDocument,
      {
        title: "",
        file: null,
      },
    ]);
  };

  const handleDeletePlaintiff = (index: number) => {
    setPlaintiffField((prevPlaintiff) =>
      prevPlaintiff.filter((_, i) => i !== index)
    );
  };
  const handleDeleteDefendant = (index: number) => {
    setDefendantField((prevDefendant) =>
      prevDefendant.filter((_, i) => i !== index)
    );
  };
  const validateEmail = (email: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const validatePhone = (phone: string) => {
    const re = /^\d+$/;
    return re.test(phone) && phone.length >= 10;
  };

  const handleAddDefendantField = () => {
    setDefendantField([
      ...defendantField,
      {
        first_name: "",
        partyType: "",
        email: "",
        address: "",
        phone_number: "",
        last_name: "",
        other_name: "",
      },
    ]);
  };
  const handleAddPlaintiffField = () => {
    setPlaintiffField([
      ...plaintiffField,
      {
        partyType: "",
        first_name: "",
        last_name: "",
        other_name: "",
        email: "",
        address: "",
        phone_number: "",
      },
    ]);
  };

  const handleNext = () => {
    setCurrentStep((prev) => prev + 1);
  };
  const handlePrev = () => {
    setCurrentStep((prev) => prev - 1);
  };
  const handleRemoveFileCaseField = (index: number) => {
    const updatedCaseDoc = caseDocument.map((doc, i) =>
      i === index ? { ...doc, file: null } : doc
    );

    setCaseDocument(updatedCaseDoc);
  };
  const handleRemoveCaseField = (index: number) => {
    const updatedCaseField = caseDocument.filter((_, i) => i !== index);
    setCaseDocument(updatedCaseField);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    validateCounsel();
  };
  const getCase = useAppSelector(getSelectedCase);
  const [errorPlaintiff, setErrorPlaintiff] = useState("");
  const [errorDefendant, setErrorDefendant] = useState("");

  const validateForm = () => {
    let isValid = true;
    let plaintiffError = "";
    let defendantError = "";

    for (const plaintiff of plaintiffField) {
      if (
        !plaintiff.first_name.trim() ||
        !plaintiff.last_name.trim() ||
        !plaintiff.other_name.trim() ||
        !plaintiff.partyType.trim() ||
        !plaintiff.phone_number.trim() ||
        !plaintiff.address.trim()
      ) {
        isValid = false;
        plaintiffError = "Some fields are empty for plaintiff";
        break;
      }
      if (!validateEmail(plaintiff.email)) {
        isValid = false;
        plaintiffError = "Invalid email for plaintiff";
        break;
      }
      if (!validatePhone(plaintiff.phone_number)) {
        isValid = false;
        plaintiffError = "Invalid phone number for plaintiff";
        break;
      }
    }

    for (const defendant of defendantField) {
      if (
        !defendant.first_name.trim() ||
        !defendant.last_name.trim() ||
        !defendant.other_name.trim() ||
        !defendant.partyType.trim() ||
        !defendant.phone_number.trim() ||
        !defendant.address.trim()
      ) {
        isValid = false;
        defendantError = "Some fields are empty for defendant";
        break;
      }
      if (!validateEmail(defendant.email)) {
        isValid = false;
        defendantError = "Invalid email for defendant";
        break;
      }
      if (!validatePhone(defendant.phone_number)) {
        isValid = false;
        defendantError = "Invalid phone number for defendant";
        break;
      }
    }

    setErrorPlaintiff(plaintiffError);
    setErrorDefendant(defendantError);

    setIsFormValid(isValid);

    return isValid;
  };

  const validateCounsel = () => {
    let isValid = true;
    let counsel_error = "";
    if (
      !formdata.counsel_detail_first_name.trim() ||
      !formdata.counsel_detail_last_name.trim() ||
      !formdata.counsel_detail_address.trim() ||
      !formdata.counsel_detail_last_name.trim() ||
      !formdata.counsel_detail_phone_number.trim() ||
      !formdata.counsel_detail_whatsapp_number.trim() ||
      !formdata.counsel_detail_email.trim()
    ) {
      isValid = false;

      counsel_error = "All field are required";
    }

    if (!validateEmail(formdata.counsel_detail_email)) {
      isValid = false;
    }
    if (
      !validatePhone(
        formdata.counsel_detail_phone_number ||
          formdata.counsel_detail_whatsapp_number
      )
    ) {
      isValid = false;
    }
    setCounselValid(isValid);
    setCounselError(counsel_error);
    return isValid;
  };

  const [preloaderState, setPreloaderState] = useState<
    "creating" | "almostDone" | "success" | "error" | null
  >(null);
  const handleCreateCase = async () => {
    const parties = [
      ...plaintiffField.map((plaintiff) => ({
        first_name: plaintiff.first_name,
        last_name: plaintiff.last_name,
        other_name: plaintiff.other_name,
        phone_number: plaintiff.phone_number,
        email: plaintiff.email,
        title: plaintiff.partyType,
        address: plaintiff.address,
      })),
      ...defendantField.map((defendant) => ({
        first_name: defendant.first_name,
        last_name: defendant.last_name,
        other_name: defendant.other_name,
        phone_number: defendant.phone_number,
        email: defendant.email,
        title: defendant.partyType,
        address: defendant.address,
      })),
    ];

    const payload = {
      parties,
      documents: caseDocument.map((doc) => ({
        title: doc.title,
        file_path: doc.file,
      })),

      counsel_detail_first_name: formdata.counsel_detail_first_name,
      counsel_detail_last_name: formdata.counsel_detail_last_name,
      counsel_detail_firm_name: formdata.counsel_detail_firm_name,
      counsel_detail_email: formdata.counsel_detail_email,
      counsel_detail_phone_number: formdata.counsel_detail_phone_number,
      counsel_detail_whatsapp_phone_number:
        formdata.counsel_detail_whatsapp_number,
      counsel_detail_address: formdata.counsel_detail_address,
    };

    try {
      setPreloaderState("creating");

      const response = await Api.post(
        "/lawyer/case-folders",
        payload,
        getFormDataHeader()
      );

      if (response) {
        setTimeout(() => setPreloaderState("almostDone"), 1000);

        setTimeout(() => {
          setPreloaderState("success");
          setSuccessModal(true);
          toast.success(response?.data?.message);
        }, 2000);
      }
    } catch (error: any) {
      setPreloaderState("error");
      toast.error(error?.response?.data?.message);
    } finally {
      setTimeout(() => setPreloaderState(null), 3000);
    }
  };

  useEffect(() => {
    return () => {
      fileInputRef.current = [];
    };
  }, []);

  return (
    <main className="py-7 px-7">
      <img
        src={GO_BACK}
        className="w-[32px] h-[32px] cursor-pointer"
        alt=""
        onClick={() => window.history.back()}
      />

      <div className="flex items-center justify-center flex-col mt-20">
        <span className="text-[28px] font-semibold text-black">
          Create new case folder
        </span>
        <div className="flex items-center gap-x-5 mt-5">
          <StepSignal
            step={1}
            currentStep={currentStep}
            title="Description of parties"
          />
          <StepSignal
            step={2}
            currentStep={currentStep}
            title="Details of counsel"
          />
          <StepSignal
            step={3}
            currentStep={currentStep}
            title="Upload documents"
          />
          <StepSignal step={4} currentStep={currentStep} title="Preview " />
        </div>

        {currentStep === 1 && (
          <div>
            <div className="w-[575px] flex flex-col gap-y-5 h-full shadow-lg bg-white px-16 py-10 mt-6">
              <span className="text-[20px] font-semibold text-black">
                Description of parties
              </span>

              {plaintiffField.map((plaintiff, i) => (
                <div className="flex flex-col items-start" key={i}>
                  <select
                    name="partyType"
                    value={plaintiff.partyType}
                    onChange={(e) => handleClaimantInputChange(e, i)}
                    className={`  ${
                      errorPlaintiff && !plaintiff.partyType && "border-red-500"
                    } w-full outline-none bg-transparent placeholder:text-[16px] text-[16px] placeholder:text-black placeholder:font-semibold font-semibold py-4 px-3 `}
                  >
                    <option value="">Select party</option>
                    <option value="CLAIMANT">Plaintiff</option>
                    <option value="APPLICANT">Applicant</option>
                    <option value="PETITIONAL">Petitional</option>
                  </select>
                  <div className="flex w-full flex-col gap-y-5 mb-5">
                    <input
                      type="text"
                      name="first_name"
                      className={` ${
                        errorPlaintiff &&
                        !plaintiff.first_name &&
                        "border-red-500"
                      } w-full border  rounded outline-none bg-transparent placeholder:text-sm placeholder:text-black py-4 px-3`}
                      placeholder="First name"
                      onChange={(e) => handleClaimantInputChange(e, i)}
                      value={plaintiff.first_name}
                    />
                    <input
                      type="text"
                      name="last_name"
                      className={` ${
                        errorPlaintiff &&
                        !plaintiff.last_name &&
                        "border-red-500"
                      } w-full border  rounded outline-none bg-transparent placeholder:text-sm placeholder:text-black py-4 px-3`}
                      placeholder="Last name"
                      onChange={(e) => handleClaimantInputChange(e, i)}
                      value={plaintiff.last_name}
                    />
                    <input
                      type="text"
                      name="other_name"
                      className={` ${
                        errorPlaintiff &&
                        !plaintiff.other_name &&
                        "border-red-500"
                      } w-full border $ rounded outline-none bg-transparent placeholder:text-sm placeholder:text-black py-4 px-3`}
                      placeholder="Other name"
                      onChange={(e) => handleClaimantInputChange(e, i)}
                      value={plaintiff.other_name}
                    />
                  </div>
                  <input
                    type="email"
                    name="email"
                    className={` ${
                      errorPlaintiff && !plaintiff.email && "border-red-500"
                    } w-full border  mb-5 rounded outline-none bg-transparent placeholder:text-sm placeholder:text-black py-4 px-3`}
                    placeholder="Email"
                    onChange={(e) => handleClaimantInputChange(e, i)}
                    value={plaintiff.email}
                  />
                  <div className="flex w-full flex-col mb-5">
                    <input
                      type="text"
                      name="address"
                      className={` ${
                        errorPlaintiff && !plaintiff.address && "border-red-500"
                      } w-full border  rounded outline-none bg-transparent placeholder:text-sm placeholder:text-black py-4 px-3`}
                      placeholder="Address"
                      onChange={(e) => handleClaimantInputChange(e, i)}
                      value={plaintiff.address}
                    />
                  </div>
                  <input
                    type="text"
                    name="phone_number"
                    className={` ${
                      errorPlaintiff &&
                      !plaintiff.phone_number &&
                      "border-red-500"
                    } w-full border  mb-5 rounded outline-none bg-transparent placeholder:text-sm placeholder:text-black py-4 px-3`}
                    placeholder="Phone number"
                    onChange={(e) => handleClaimantInputChange(e, i)}
                    value={plaintiff.phone_number}
                  />
                  {/* Add/Delete buttons here */}
                  <div className="flex items-center justify-between w-full">
                    {plaintiffField.length > 1 && (
                      <button
                        className=" flex items-center gap-x-2 text-sm rounded py-3  text-[#272755] font-semibold"
                        onClick={() => handleDeletePlaintiff(i)}
                      >
                        <span className="font-medium text-sm text-red-500">
                          Delete Field
                        </span>
                        <FaMinusCircle size={20} color="red" />
                      </button>
                    )}
                    <button
                      className={`flex items-center ${
                        plaintiffField.length <= 1 &&
                        "w-full items-end justify-end"
                      } gap-x-2 text-sm rounded py-3  text-[#272755] font-semibold`}
                      onClick={handleAddPlaintiffField}
                    >
                      <span className="font-medium text-sm text-black">
                        Add Claimant
                      </span>
                      <img
                        src={BLUE_ADD}
                        className="w-[20px] h-[20px]"
                        alt=""
                      />
                    </button>
                  </div>
                </div>
              ))}
              {defendantField.map((defendant, i) => (
                <div className="flex flex-col items-start">
                  <select
                    name="partyType"
                    id=""
                    value={defendant.partyType}
                    onChange={(e) => handleDefendantInputChange(e, i)}
                    className={` ${
                      errorDefendant && !defendant.partyType && "border-red-500"
                    } w-full outline-none bg-transparent placeholder:text-[16px] text-[16px] placeholder:text-black placeholder:font-semibold font-semibold py-4 px-3`}
                  >
                    <option value="">Select party</option>
                    <option value="DEFENDANT">Defendant</option>
                    <option value="RESPONDENT">Respondent</option>
                  </select>
                  <div className="flex w-full items-end justify-end flex-col gap-y-5 mb-5">
                    <input
                      type="text"
                      name="first_name"
                      className={` ${
                        errorDefendant &&
                        !defendant.first_name &&
                        "border-red-500"
                      } w-full border border-[#CACACA]  rounded outline-none bg-transparent placeholder:text-sm placeholder:text-black py-4 px-3`}
                      placeholder="First name"
                      onChange={(e) => handleDefendantInputChange(e, i)}
                      value={defendant.first_name}
                    />
                    <input
                      type="text"
                      name="last_name"
                      className={` ${
                        errorDefendant &&
                        !defendant.last_name &&
                        "border-red-500"
                      } w-full border border-[#CACACA]  rounded outline-none bg-transparent placeholder:text-sm placeholder:text-black py-4 px-3`}
                      placeholder="Last name"
                      onChange={(e) => handleDefendantInputChange(e, i)}
                      value={defendant.last_name}
                    />
                    <input
                      type="text"
                      name="other_name"
                      className={` ${
                        errorDefendant &&
                        !defendant.other_name &&
                        "border-red-500"
                      } w-full border border-[#CACACA]  rounded outline-none bg-transparent placeholder:text-sm placeholder:text-black py-4 px-3`}
                      placeholder="Other name"
                      onChange={(e) => handleDefendantInputChange(e, i)}
                      value={defendant.other_name}
                    />
                  </div>
                  <input
                    type="email"
                    name="email"
                    className={` ${
                      errorDefendant && !defendant.email && "border-red-500"
                    } w-full border border-[#CACACA] mb-5 rounded outline-none bg-transparent placeholder:text-sm placeholder:text-black py-4 px-3`}
                    placeholder="Email address"
                    onChange={(e) => handleDefendantInputChange(e, i)}
                    value={defendant.email}
                  />
                  <div className="flex w-full items-end justify-end flex-col mb-5">
                    <input
                      type="text"
                      name="address"
                      className={` ${
                        errorDefendant && !defendant.address && "border-red-500"
                      } w-full border border-[#CACACA]  rounded outline-none bg-transparent placeholder:text-sm placeholder:text-black py-4 px-3`}
                      placeholder="Address"
                      onChange={(e) => handleDefendantInputChange(e, i)}
                      value={defendant.address}
                    />
                  </div>
                  <input
                    type="text"
                    name="phone_number"
                    className={` ${
                      errorDefendant &&
                      !defendant.phone_number &&
                      "border-red-500"
                    } w-full border border-[#CACACA] mb-5 rounded outline-none bg-transparent placeholder:text-sm placeholder:text-black py-4 px-3`}
                    placeholder="Phone number"
                    onChange={(e) => handleDefendantInputChange(e, i)}
                    value={defendant.phone_number}
                  />

                  <div className="flex items-center justify-between w-full">
                    {defendantField.length > 1 && (
                      <button
                        className=" flex items-center gap-x-2 text-sm rounded py-3  text-[#272755] font-semibold"
                        onClick={() => handleDeleteDefendant(i)}
                      >
                        <span className="font-medium text-sm text-red-500">
                          Delete Field
                        </span>
                        <FaMinusCircle size={20} color="red" />
                      </button>
                    )}
                    <button
                      className={`flex items-center ${
                        defendantField.length <= 1 &&
                        "w-full items-end justify-end"
                      } gap-x-2 text-sm rounded py-3  text-[#272755] font-semibold`}
                      onClick={handleAddDefendantField}
                    >
                      <span className="font-medium text-sm text-black">
                        Add Defendant
                      </span>
                      <img
                        src={BLUE_ADD}
                        className="w-[20px] h-[20px]"
                        alt=""
                      />
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <button
              disabled={!isFormValid}
              className={` ${
                isFormValid ? "bg-opacity-100" : "bg-opacity-50"
              } w-full bg-[#272755] text-[17.8px] rounded py-3 mt-9 text-white font-semibold`}
              onClick={handleNext}
            >
              Next
            </button>
          </div>
        )}

        {currentStep === 2 && (
          <div className="w-[575px] h-full shadow-lg bg-white px-16 py-10 mt-6">
            <span className="text-[20px] font-semibold text-black">
              Details of Counsel
            </span>

            <div className="mt-6">
              <span className="text-[16px] font-semibold">
                Plaintiff Counsel
              </span>

              <form
                action=""
                className="flex items-start flex-col gap-y-5 py-3"
              >
                <input
                  type="text"
                  name="counsel_detail_first_name"
                  className={` ${
                    counselError &&
                    !formdata.counsel_detail_first_name &&
                    "border-red-500"
                  } w-full border border-[#CACACA] rounded outline-none bg-transparent placeholder:text-sm placeholder:text-black py-4 px-3`}
                  placeholder="First name"
                  onChange={handleInputChange}
                  value={formdata.counsel_detail_first_name}
                />
                <input
                  type="text"
                  name="counsel_detail_last_name"
                  className={` ${
                    counselError &&
                    !formdata.counsel_detail_last_name &&
                    "border-red-500"
                  } w-full border border-[#CACACA] rounded outline-none bg-transparent placeholder:text-sm placeholder:text-black py-4 px-3`}
                  placeholder="Last name"
                  onChange={handleInputChange}
                  value={formdata.counsel_detail_last_name}
                />
                <input
                  type="text"
                  name="counsel_detail_firm_name"
                  className={` ${
                    counselError &&
                    !formdata.counsel_detail_firm_name &&
                    "border-red-500"
                  } w-full border border-[#CACACA] rounded outline-none bg-transparent placeholder:text-sm placeholder:text-black py-4 px-3`}
                  placeholder="Name of  law firm"
                  onChange={handleInputChange}
                  value={formdata.counsel_detail_firm_name}
                />
                <input
                  type="email"
                  name="counsel_detail_email"
                  className={` ${
                    counselError &&
                    !formdata.counsel_detail_email &&
                    "border-red-500"
                  } w-full border border-[#CACACA] rounded outline-none bg-transparent placeholder:text-sm placeholder:text-black py-4 px-3`}
                  placeholder="Email address"
                  onChange={handleInputChange}
                  value={formdata.counsel_detail_email}
                />
                <input
                  type="text"
                  name="counsel_detail_address"
                  className={` ${
                    counselError &&
                    !formdata.counsel_detail_address &&
                    "border-red-500"
                  } w-full border border-[#CACACA] rounded outline-none bg-transparent placeholder:text-sm placeholder:text-black py-4 px-3`}
                  placeholder="Address for service"
                  onChange={handleInputChange}
                  value={formdata.counsel_detail_address}
                />
                <input
                  type="text"
                  name="counsel_detail_phone_number"
                  className={` ${
                    counselError &&
                    !formdata.counsel_detail_phone_number &&
                    "border-red-500"
                  } w-full border border-[#CACACA] rounded outline-none bg-transparent placeholder:text-sm placeholder:text-black py-4 px-3`}
                  placeholder="Phone number"
                  onChange={handleInputChange}
                  value={formdata.counsel_detail_phone_number}
                />
                <input
                  type="text"
                  name="counsel_detail_whatsapp_number"
                  className={` ${
                    counselError &&
                    !formdata.counsel_detail_whatsapp_number &&
                    "border-red-500"
                  } w-full border border-[#CACACA] rounded outline-none bg-transparent placeholder:text-sm placeholder:text-black py-4 px-3`}
                  placeholder="Whatsapp number"
                  onChange={handleInputChange}
                  value={formdata.counsel_detail_whatsapp_number}
                />
              </form>
            </div>
            <button
              disabled={!counselValid}
              className={` ${
                counselValid ? "bg-opacity-100" : "bg-opacity-50"
              } w-full bg-[#272755] text-[17.8px] rounded py-3 mt-9 text-white font-semibold`}
              onClick={handleNext}
            >
              Next
            </button>
            <button
              className="flex items-center gap-x-3  text-[17.8px] rounded py-3 mt-9 text-#272755 font-semibold"
              onClick={handlePrev}
            >
              <FaArrowLeft color="#272755" size={20} />
              <span>Back</span>
            </button>
          </div>
        )}

        {currentStep === 3 && (
          <div className="w-[575px] h-full shadow-lg bg-white p-10 mt-6">
            <span className="text-[20px] font-semibold text-black">
              Upload case documents
            </span>
            {caseDocument.map((docs, index) => (
              <>
                <div className="mt-2 flex-col flex gap-y-2" key={index}>
                  <span className="text-[16px] font-semibold">Case title</span>
                  <input
                    type="text"
                    name="title"
                    className="w-full border border-[#CACACA] rounded outline-none bg-transparent placeholder:text-sm placeholder:text-black py-4 px-3"
                    placeholder="Enter case title"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleCaseInputChange(e, index)
                    }
                    value={docs.title}
                  />
                </div>

                <div className="mt-6 flex flex-col gap-y-2 items-end justify-end">
                  <span className="text-[16px] font-semibold flex w-full items-start">
                    Upload documents
                  </span>
                  {docs.file ? (
                    <div className="w-full h-full border border-[#C7C7CC] p-10 rounded-lg">
                      <div className="flex items-start gap-x-3">
                        <img src={PDF} className="w-[28px] h-[28px]" alt="" />
                        <div>
                          <div className="flex items-start justify-between">
                            <div className="flex items-start gap-x-3">
                              <div className="flex flex-col gap-y-1">
                                <span className="text-sm font-medium text-[#232A35]">
                                  {docs.file.name}
                                </span>
                                <span className="text-[#667085] font-normal text-sm">
                                  PDF (max .5 MB)
                                </span>
                              </div>
                            </div>
                            <div className="flex items-center h-[16px] w-[16px] justify-center rounded-full bg-[#272755] ">
                              <FaCheck size={10} color="white" />
                            </div>
                          </div>
                          <div className="flex items-center gap-x-3">
                            <div className="w-[268px] h-[8px] bg-[#272755] rounded-lg"></div>
                            <span>100 %</span>
                          </div>
                          <div className="flex items-center gap-x-2">
                            <button className="text-sm font-semibold text-[#6C757D]">
                              View
                            </button>
                            <button
                              className="text-sm font-semibold text-[#6C757D]"
                              onClick={() => handleRemoveFileCaseField(index)}
                            >
                              Delete
                            </button>
                            <button
                              className="text-sm font-semibold text-[#6C757D]"
                              onClick={() =>
                                // @ts-ignore
                                // document
                                //   .querySelector(".file_upload")
                                //   [index].click()
                                fileInputRef.current[index].click()
                              }
                            >
                              Upload another
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="w-full h-[126px] cursor-pointer  mt-3 border border-[#EAECF0] flex items-center justify-center flex-col rounded-md"
                      onClick={() =>
                        // @ts-ignore
                        fileInputRef.current[index].click()
                      }
                    >
                      <img
                        src={UPLOAD_ICON}
                        className="w-[40px] h-[40px]"
                        alt=""
                      />

                      <span className="text-[14px] font-semibold">
                        Click to upload{" "}
                        <span className="text-[#667085]">
                          Example receipt.pdf
                        </span>
                      </span>

                      <span className="text-[#667085] text-sm">
                        PDF (max 5mb)
                      </span>
                    </div>
                  )}

                  <input
                    type="file"
                    accept=".pdf"
                    className="file_upload sr-only"
                    ref={(ref: any) => (fileInputRef.current[index] = ref)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleFileChange(e, index)
                    }
                  />
                  <div className="flex items-center justify-between w-full">
                    {caseDocument.length > 1 && (
                      <button
                        className=" flex items-center gap-x-2 text-sm rounded py-3  text-[#272755] font-semibold"
                        onClick={() => handleRemoveCaseField(index)}
                      >
                        <span className="font-medium text-sm text-red-500">
                          Delete Field
                        </span>
                        <FaMinusCircle size={20} color="red" />
                      </button>
                    )}
                    <button
                      type="button"
                      onClick={handleAddCaseField}
                      className={` ${
                        caseDocument.length <= 1 &&
                        "w-full flex justify-end items-end"
                      } `}
                    >
                      <img
                        src={ADD_BUTTON}
                        className="w-[20px] h-[20px] "
                        alt=""
                      />
                    </button>
                  </div>
                </div>
              </>
            ))}
            <button
              className={`w-full ${
                loading ? "bg-opacity-50 cursor-wait" : "bg-opacity-100"
              } bg-[#272755] text-[17.8px] rounded py-3 mt-9 text-white font-semibold`}
              onClick={handleNext}
            >
              Next
            </button>
            <button
              className={`flex items-center gap-x-3  text-[17.8px] rounded py-3 mt-9 text-[#272755] font-semibold`}
              onClick={handlePrev}
            >
              <FaArrowLeft size={20} color="#272755" />
              <span>Back</span>
            </button>
          </div>
        )}
        {currentStep === 4 && (
          <div className="w-[575px] h-full shadow-lg bg-white p-10 mt-6 flex flex-col gap-y-4">
            <span className="text-[20px] font-semibold text-black">
              Preview information
            </span>

            <div>
              <span className="text-[20px] font-semibold text-black">
                Description of parties
              </span>
              <div className="flex items-start flex-col gap-y-2 mt-3">
                {plaintiffField.map((plaintiff) => (
                  <div className="flex items-start w-full flex-col gap-y-2 mt-3">
                    <span className="text-[16px] font-semibold text-black">
                      {plaintiff.partyType}
                    </span>
                    <PreviewBox
                      title="First name"
                      value={plaintiff.first_name}
                    />
                    <PreviewBox title="Last name" value={plaintiff.last_name} />
                    <PreviewBox
                      title="Other name"
                      value={plaintiff.other_name}
                    />
                    <PreviewBox title="Email address" value={plaintiff.email} />
                    <PreviewBox title="Address" value={plaintiff.address} />
                    <PreviewBox
                      title="Phone number"
                      value={plaintiff.phone_number}
                    />
                  </div>
                ))}
              </div>
              <div className="flex items-start flex-col gap-y-2 mt-3">
                {defendantField.map((defendant) => (
                  <div className="flex items-start w-full flex-col gap-y-2 mt-3">
                    <span className="text-[16px] font-semibold text-black">
                      {defendant.partyType}
                    </span>
                    <PreviewBox
                      title="First name"
                      value={defendant.first_name}
                    />
                    <PreviewBox title="Last name" value={defendant.last_name} />
                    <PreviewBox
                      title="Other name"
                      value={defendant.other_name}
                    />
                    <PreviewBox title="Email address" value={defendant.email} />
                    <PreviewBox title="Address" value={defendant.address} />
                    <PreviewBox
                      title="Phone number"
                      value={defendant.phone_number}
                    />
                  </div>
                ))}
              </div>
              <div className="mt-4">
                <span className="text-[20px] font-semibold text-black">
                  Description of parties
                </span>

                <div className="flex items-start flex-col gap-y-2 mt-3">
                  <span className="text-[16px] font-semibold text-black">
                    Claimant counsel
                  </span>
                  <PreviewBox
                    title="First name"
                    value={formdata.counsel_detail_first_name}
                  />
                  <PreviewBox
                    title="Last name"
                    value={formdata.counsel_detail_last_name}
                  />
                  <PreviewBox
                    title="Email address"
                    value={formdata.counsel_detail_email}
                  />
                  <PreviewBox
                    title="Address"
                    value={formdata.counsel_detail_address}
                  />
                  <PreviewBox
                    title="Phone number"
                    value={formdata.counsel_detail_phone_number}
                  />
                  <PreviewBox
                    title="Whatsapp number"
                    value={formdata.counsel_detail_whatsapp_number}
                  />
                  <PreviewBox
                    title="Name of firm"
                    value={formdata.counsel_detail_firm_name}
                  />
                </div>
              </div>

              <div className="mt-4">
                <span className="text-[20px] font-semibold text-black">
                  Uploaded documents
                </span>
                <div className="flex flex-col gap-y-3">
                  {caseDocument.map(
                    (doc, index) =>
                      doc.file && (
                        <div className="flex flex-col item-start gap-y-3">
                          <span className="font-medium text-[16px] text-black">
                            {doc.title}
                          </span>

                          <div className="w-full h-full border border-[#C7C7CC] p-10 rounded-lg">
                            <div className="flex items-start gap-x-3">
                              <img
                                src={PDF}
                                className="w-[28px] h-[28px]"
                                alt=""
                              />
                              <div>
                                <div className="flex items-start justify-between">
                                  <div className="flex items-start gap-x-3">
                                    <div className="flex flex-col gap-y-1">
                                      <span className="text-sm font-medium text-[#232A35]">
                                        {doc.file.name}
                                      </span>
                                      <span className="text-[#667085] font-normal text-sm">
                                        PDF (max .5 MB)
                                      </span>
                                    </div>
                                  </div>
                                  <div className="flex items-center h-[16px] w-[16px] justify-center rounded-full bg-[#272755] ">
                                    <FaCheck size={10} color="white" />
                                  </div>
                                </div>
                                <div className="flex items-center gap-x-3">
                                  <div className="w-[268px] h-[8px] bg-[#272755] rounded-lg"></div>
                                  <span>100 %</span>
                                </div>
                                <div className="flex items-center gap-x-2">
                                  <button className="text-sm font-semibold text-[#6C757D]">
                                    View
                                  </button>
                                  <button
                                    className="text-sm font-semibold text-[#6C757D]"
                                    onClick={() => handleRemoveCaseField(index)}
                                  >
                                    Delete
                                  </button>
                                  <button
                                    className="text-sm font-semibold text-[#6C757D]"
                                    onClick={() =>
                                      // @ts-ignore
                                      // document
                                      //   .querySelector(".file_upload")
                                      //   [index].click()
                                      fileInputRef.current[index].click()
                                    }
                                  >
                                    Upload another
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                  )}
                </div>
              </div>
            </div>
            <button
              className={`w-full ${
                loading ? "bg-opacity-50 cursor-wait" : "bg-opacity-100"
              } bg-[#272755] text-[17.8px] rounded py-3 mt-9 text-white font-semibold`}
              onClick={handleCreateCase}
            >
              {loading ? "please wait..." : "  Submit"}
            </button>
          </div>
        )}

        <Modal
          open={successModal}
          close={() => setSuccessModal(false)}
          maxWidth={"xs"}
        >
          <div className="flex items-center justify-center flex-col px-5 py-5">
            <img src={SUCCESS_IMAGE} className="w-[211px] h-[171px]" alt="" />
            <span className="text-[20px] font-semibold">
              Submitted successfully
            </span>

            <button
              className={`w-full bg-[#272755] text-[17.8px] rounded py-3 mt-9 text-white font-semibold`}
              onClick={() => {
                setSuccessModal(false);
                navigate("/lawyer/my-files");
              }}
            >
              Close
            </button>
          </div>
        </Modal>
      </div>
      <Preloader
        preloaderState={preloaderState}
        action={"Creating a case folder"}
      />
    </main>
  );
}

export default CreateCase;

export const PreviewBox = ({
  title,
  value,
}: {
  title: string;
  value: string;
}) => {
  return (
    <div className="w-full flex flex-col gap-y-1 items-start">
      <span className="text-sm font-normal text-[#9A9A9A]">{title}</span>
      <input
        type="text"
        name="counsel_detail_whatsapp_number"
        className="w-full border border-[#CACACA] rounded outline-none bg-transparent placeholder:text-sm placeholder:text-black py-4 px-3"
        placeholder=""
        // onChange={handleInputChange}
        value={value}
      />
    </div>
  );
};
